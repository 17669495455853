import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

  singlePrice: {
    textAlign: 'center',
    marginBottom: '2rem',
  },

  priceAmount: {
    fontSize: '2rem',
    fontWeight: '800',
    color: [theme.palette.primary.main],

    '& span': {
      fontSize: '4rem',
    },
  },

  infoTitle: {
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    paddingBottom: '.5rem',
    marginBottom: '.5rem',
    borderBottom: '1px solid',
  },

  [theme.breakpoints.up('md')]: {
    singlePrice: {
      padding: '0 2rem',
    },
  },

  [theme.breakpoints.up('lg')]: {
    singlePrice: {
      padding: '0 4rem',
    },
  },
}))

const DesignPrices = () => {

  const classes = useStyles()

  return (

    <Box className={classes.wrap} component="section" id="design-prices">
      <Box className={`${classes.wrapper} wrapper`} py={5}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
              <Typography variant="h2" className={`${classes.title} title`}><span>цены</span> на дизайн</Typography>
              <Typography variant="body1" className={`${classes.subtitle} first subtitle`}>Цена на дизайн сильно зависит от сложности поставленной задачи, но примерную стоимость мы постарались озвучить ниже. Также мы предоставляем услуги с фиксированной ежемесячной платой, в рамках которой будет разрабатываться весь объем дизайна вашего бизнеса.</Typography>
            </Box>
            <Box className={classes.pricesBox}>
              <Grid container>
                <Grid item md={4}>
                  <Box className={classes.singlePrice}>
                    <Box className={classes.priceAmountBox}>
                      <p className={classes.priceAmount}>от <span>5000</span> ₽</p>
                    </Box>
                    <Box className={classes.priceInfoBox}>
                      <h3 className={classes.infoTitle}>
                        Разработка бренда
                      </h3>
                      <p>Разработка логотипа и элементом фирменного стиля.</p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box className={classes.singlePrice}>
                    <Box className={classes.priceAmountBox}>
                      <p className={classes.priceAmount}>от <span>500</span> ₽</p>
                    </Box>
                    <Box className={classes.priceInfoBox}>
                      <h3 className={classes.infoTitle}>
                        web-дизайн
                      </h3>
                      <p>Дизайн различных статичных или анимированных банеров для использования на вашем сайте.</p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box className={classes.singlePrice}>
                    <Box className={classes.priceAmountBox}>
                      <p className={classes.priceAmount}>от <span>1000</span> ₽</p>
                    </Box>
                    <Box className={classes.priceInfoBox}>
                      <h3 className={classes.infoTitle}>
                        Графический дизайн
                      </h3>
                      <p>Дизайн и допечатная подготовка визиток, рекламных флаеров, каталогов, листовок и прочей печатной продукции, билбордов и вывесок. Также интерьерный дизайн.</p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default DesignPrices
