import React from 'react'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ArrowIcon from '../../svg/arrowRightGrdnt.svg'
import { useModal } from '../modal/modal-context';

const useStyles = makeStyles((theme) => ({

  wrapper: {
    height: '100vh',
    background: [theme.palette.gradientDark.main],
    color: '#fff',
    alignItems: 'flex-end',
    position: 'relative',
    overflow: 'hidden',
  },

  circle: {
    position: 'absolute',
    width: '130vw',
    height: '130vw',
    transform: 'translate(50%, -50%)',
    right: '50%',
    top: '0',
    borderRadius: '50%',
    background: [theme.palette.gradientBlue.main],
    zIndex: 1,

    '& svg': {
      width: '90vw',
      height: 'auto',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
      bottom: '-20vw',
      left: '50%',
    },
  },

  contentBox: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 3,
  },

  title: {

    fontSize: '2rem',

    textTransform: 'uppercase',

    '& span': {
      background: [theme.palette.gradientBlue.main],
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    },
  },

  subtitle: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    fontWeight: 400,
  },

  '@media (min-width: 400px)': {

    circle: {

      '& svg': {
        width: '78vw',
        height: '85vw',
        bottom: '-30vw',
      },
    },
  },

  '@media (min-width: 600px)': {
    wrapper: {
      minHeight: '820px',
    },
  },

  '@media (min-width: 768px)': {
    wrapper: {
      minHeight: '1024px',
    },
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      alignItems: 'center',
      zIndex: 2,
      minHeight: '50vh',
      height: '900px',

      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '340px',
        top: 'calc(50% - 170px)',
        left: 0,
        background: `url(./images/ascent.svg) center center / contain no-repeat`,
        zIndex: 0,
      },
    },

    subtitle: {
      fontSize: '1.5rem',
    },

    circle: {
      transform: 'translate(0, 0)',
      width: '1107px',
      height: '1107px',
      left: '50vw',
      top: 'auto',
      bottom: '-478px',

      '& svg': {
        transform: 'translate(0, 0)',
        width: 'auto',
        height: 'auto',
        bottom: 'auto',
        top: '-120px',
        left: '70px',
      },
    },

    title: {
      fontSize: '3.5rem',
    },

    contentBox: {
      textAlign: 'left',
    }
  },

}))

const DesignMainBanner = ({svg, content}) => {

  const classes = useStyles()

  const {show} = useModal()

  const modalTitle = 'Консультация по дизайну'
  const modalDesc = 'Заполните форму заявки, и наш менеджер свяжется с вами в ближайшее время 🙂'
  const modalFields = {
    name: true,
    phone: true,
    mail: true,
  }

  return (
    <Box
      component="section"
      id="lp-main-banner"
    >
      <Box className={`${classes.wrapper} wrapper`} display="flex" py={3}>
        <div className={classes.circle}>
          {svg}
        </div>

        <Container maxWidth="xl">
          <Box className={classes.contentBox}>
            <Grid
              container
            >
              <Grid item md={6}>
                <Box className={classes.titleBox} mb={2}>
                  <Typography variant="h1" className={classes.title}>{content.ttlBlue}{content.title}</Typography>
                </Box>
                <Box 
                  className={classes.subtitleBox}
                  mb={{xs: 5, md: 8}}
                >
                  <h2 className={classes.subtitle}>{content.subtitle}</h2>
                </Box>
                <Box className={classes.btnBox}>
                  {
                    content.withModal ?
                    <Button
                      variant="outlined"
                      color="inherit"
                      endIcon={<ArrowIcon />}
                      size="large"
                      onClick={() => show(modalTitle, modalDesc, modalTitle, modalFields)}
                    >
                      {content.buttonText}
                    </Button> :
                    <Button
                      variant="outlined"
                      color="inherit"
                      endIcon={<ArrowIcon />}
                      size="large"
                      href={content.buttonLink}
                    >
                      {content.buttonText}
                    </Button>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default DesignMainBanner
