import React from 'react'
import DesignPrices from '../../../components/design-page/design-prices'
import DesignInfoSection from '../../../components/design-page/info-section'
import DesignMainBanner from '../../../components/design-page/main-banner'
import DesignWorks from '../../../components/design-page/our-works'
import Layout from '../../../components/layout'

import Illustration from '../../../svg/design-page.svg'
import FormSection from '../../../components/page-parts/form-section';
import Seo from '../../../components/seo'

const DesignPage = () => {

  const bannerContent = {
    ttlBlue: <span>дизайн</span>,
    title: ' и проектирование',
    subtitle: 'Дизайн любой сложности и любых направлений',
    buttonText: 'оставить заявку',
    buttonLink: null,
    withModal: true,
  }

  const pageTitle = 'Услуги графического и web дизайна. Дизайн и проектирование'
  const pageDescription = 'Разработаем для вас логотип и фирменный стиль. При необходимости готовы предоставить полный спектр услуг по графическому и web-дизайну. Графический дизайн для вашей компании или продукта, поможет выгодно отстроиться от конкурентов, завоевать доверие клиентов и партнеров.'

  // const keywords = ''

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <DesignMainBanner content={bannerContent} svg={<Illustration />} />
      <DesignInfoSection />
      <DesignWorks />
      <DesignPrices />
      <FormSection />
    </Layout>
  )
}

export default DesignPage
