import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container, Hidden, Typography } from '@material-ui/core'

import LogoGray from '../../svg/logo-grey.svg'
import StagesBox from '../page-parts/stages'

import Num1 from '../../svg/stages-numbers/num1.svg'
import Num2 from '../../svg/stages-numbers/num2.svg'
import Num3 from '../../svg/stages-numbers/num3.svg'
import Num4 from '../../svg/stages-numbers/num4.svg'
import Num5 from '../../svg/stages-numbers/num5.svg'
import Num6 from '../../svg/stages-numbers/num6.svg'

import ArrowIcon from '../../svg/arrowRightDark.svg'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    padding: '50px 0',
  },

  content: {
    position: 'relative',
    zIndex: 2,
    marginBottom: '100px',
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      padding: '60px 0 100px',
    },

    titleBox: {
      width: '60%',
    },

    subtitle: {

      '&.first': {
        paddingBottom: '1rem',
        marginBottom: '1rem',
        borderBottom: '1px solid',
      }
    },

    content: {
      marginBottom: '180px',
    },

    circle: {
      position: 'absolute',
      borderRadius: '50%',
      background: 'linear-gradient(180deg, #ffffff 0%, #c9c9c9 100%)',
      opacity: .3,
      width: '1107px',
      height: '1107px',
      left: '50vw',
      top: '-629px',
      zIndex: 1,

      '& svg': {
        position: 'absolute',
        bottom: '240px',
        left: '350px',
        width: '400px',
        height: 'auto',
      },
    },

    stagesBox: {
      position: 'relative',
      zIndex: 5,
    },
  }

}))

const DesignInfoSection = () => {

  const classes = useStyles()

  const stages = [
    {
      title: 'Маркетинговые исследования',
      text: 'Проводим глубинные интервью, анализируем конкурентов, формируем позиционирование и платформу бренда.',
      num: <Num1/>,
    },
    {
      title: 'Бренд-аудит',
      text: 'Проверяем ваш бренд по сотне пунктов, даём четкие и понятные рекомендации по его улучшению. Никакой воды, все по делу.',
      num: <Num2/>,
    },
    {
      title: 'Авторский надзор',
      text: 'Осуществляем контроль над соблюдением стандартов, разработанного нами образа вашего бренда.',
      num: <Num3/>,
    },
    {
      title: 'Логотип',
      text: 'Создаем логотипы с нуля, делаем рестайлинг, детально работаем над самым привлекательным образом для вашей целевой аудитории.',
      num: <Num4/>,
    },
    {
      title: 'Фирменный стиль',
      text: '«Одеваем» ваш бренд при помощи продуманных носителей стиля для того, чтобы он был цельным и органичным.',
      num: <Num5/>,
    },
    {
      title: 'Брендбук',
      text: 'Создаем «библию компании», развиваем ее вместе с вами, прорабатываем мельчайшие детали, о которых вы раньше даже и не задумывались, мыслим на перспективу и предлагаем решения на несколько лет вперед.',
      num: <Num6/>,
    },
  ]

  return (
    <Box component="section" className={classes.infoWrap} id="design-info-section">
      <Box className={`${classes.wrapper} wrapper`}>
        <Hidden xsDown>
          <div className={classes.circle}>
            <LogoGray />
          </div>
        </Hidden>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
              <Typography variant="h2" className={`${classes.title} title`}><span>дизайн</span> и проектирование</Typography>
              <Typography variant="body1" className={`${classes.subtitle} first subtitle`}>Разработаем для вас логотип и фирменный стиль. При необходимости готовы предоставить полный спектр услуг по графическому и web-дизайну</Typography>
              <Typography variant="body1" className={`${classes.subtitle} subtitle`}>Графический дизайн для вашей компании или продукта, поможет выгодно  отстроиться от конкурентов, завоевать доверие клиентов и партнеров.</Typography>
            </Box>
          </Box>
        </Container>
        <Box className={classes.stagesBox}>
          <StagesBox content={stages} />
        </Box>
        <Box className={classes.buttonBox} mt={5}>
          <Container maxWidth="xl">
            <Button href="#!" variant="outlined" color="inherit" endIcon={<ArrowIcon />} size="large">Портфолио</Button>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default DesignInfoSection
