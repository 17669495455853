import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowIcon from '../../svg/arrowRight.svg'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '50px 0 80px',
    color: '#fff',
    background: [theme.palette.gradientDark.main],
  },

  galleryWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& > *:not(:last-child)': {
      marginRight: '20px',
    },
  },

  singleImg: {
    flexBasis: 'auto',
    maxWidth: '576px',
    display: 'block',
    marginBottom: '20px',
  },

  galleryBtnBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [theme.breakpoints.up('md')]: {

    titleBox: {
      width: 'calc(100% / 12 * 8)',
      marginBottom: '4rem',

      '& .subtitle.first': {
        paddingBottom: '1.5rem',
        marginBottom: '1.5rem',
        borderBottom: '1px solid',
      },
    },

    galleryWrap: {
      justifyContent: 'stretch',
    },

    galleryBtnBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

const DesignWorks = () => {

  const classes = useStyles()

  return (
    <Box component="section" className={classes.wrap}>
      <Box className={`${classes.wrapper} wrapper`}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
              <Typography variant="h2" className={`${classes.title} title`}>графический <span>дизайн</span></Typography>
              <Typography variant="body1" className="subtitle first">Мы готовы взять на себя полное сопровождение вашей компании по всем направлениям дизайна, как разовое сотрудничество над проектом, так и на постоянной основе с фиксированной ежемесячной оплатой.</Typography>
              <Typography variant="body1" className="subtitle">Дизайнеры нашей компании имеют многолетний опыт в реализации различных по сложности проектов, вот несколько из них.</Typography>
            </Box>
          </Box>
          <Box className={classes.galleryBox}>
            <Gallery />
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default DesignWorks


const Gallery = () => {

  const {slides} = useStaticQuery(graphql`
    query GalleryImagesQuery {
      slides: allFile(filter: {relativeDirectory: {eq: "designs"}}) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Box className={classes.galleryWrap}>
      {
        slides.edges.map(({node}, idx) => {
          return (
            <GatsbyImage
              className={classes.singleImg}
              image={getImage(node)}
              alt={`Графический дизайн ${idx}`}
              key={node.id}
              objectFit="contain"
            />
          )
        })
      }
      <Box className={classes.galleryBtnBox}>
        <Button variant="outlined" size="large" color="inherit" endIcon={<ArrowIcon />}>Все работы</Button>
      </Box>
    </Box>
  )
}
